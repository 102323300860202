import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { QuickbooksConnect } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { DatePicker, Select, SelectOption, Loading as SourLoading } from '@wayste/sour-ui';
import Loading from '../../../components/Loading';
import { useAlertDialog } from '../../../contexts';
import { allReceivableLineItemOptions } from '../../../utils/invoice-utils';
import { haulerChargeItems } from '../../../utils/shared-types';

const cellStyle = 'py-5 border-0 border-t border-solid border-[#D9DCE1] px-0';

const QuickBooksMapping = () => {
    const alert = useAlertDialog();
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showQBConnectionNeeded, setShowQBConnectionNeeded] = useState(false);
    const [qbItems, setQBItems] = useState<unknown[]>([]);
    const [qbAccounts, setQBAccounts] = useState<unknown[]>([]);
    const [qbBankAccounts, setQBBankAccounts] = useState<unknown[]>([]);
    // const [qbCreditCardAccounts, setQBCreditCardAccounts] = useState<unknown[]>([]); // Do NOT remove this, will be used shortly
    const [receivableMapping, setReceivableMapping] = useState<QuickbooksConnect.QuickbooksLineitemMappingArray>([]);
    const [payableMapping, setPayableMapping] = useState<QuickbooksConnect.QuickbooksLineitemMappingArray>([]);
    const [quickBooksSettings, setQuickBooksSettings] = useState<QuickbooksConnect.QuickbooksConnectSettings>({
        bankAccount: '',
        enableSync: false,
        haulerID: '',
        omitLineItems: ['Refund'],
        customLineItemNames: [],
        accountingIntegrationType: 'QUICKBOOKS_ONLINE',
        syncStatus: 'OFF',
        cutoffDate: '',
    });

    // const [selectedCreditCard, setSelectedCreditCard] = useState(''); // This will be changed once @Jason comes up with a schema

    const handleSetInitialMapping = (
        mappingData: QuickbooksConnect.QuickbooksLineitemMappingArray,
        itemsData: any[],
        accountsData: any[],
        settingsData: QuickbooksConnect.QuickbooksConnectSettings,
    ) => {
        const initialReceivableMapping: QuickbooksConnect.QuickbooksLineitemMappingArray = [];
        const initialPayableMapping: QuickbooksConnect.QuickbooksLineitemMappingArray = [];

        const currentReceivableMapping = mappingData.filter((item) => item.accountType === 'REVENUE_ACCOUNT');
        const currentPayableMapping = mappingData.filter((item) => item.accountType === 'EXPENSE_ACCOUNT');

        allReceivableLineItemOptions.sort().forEach((item) => {
            const found = currentReceivableMapping.find((mapItem) => mapItem.internalName === item);
            if (found) {
                initialReceivableMapping.push(found);
            } else {
                initialReceivableMapping.push({
                    id: '',
                    haulerID: '',
                    internalName: item,
                    externalName: '',
                    accountType: 'REVENUE_ACCOUNT',
                    metadata: {
                        createdAt: '',
                        createdByUserID: '',
                        lastUpdatedAt: '',
                        lastUpdatedByUserID: '',
                    },
                });
            }
        });

        const onlyPayableAccounts = accountsData.filter(
            (item) =>
                item.AccountType !== 'Accounts Receivable' &&
                item.AccountType !== 'Income' &&
                item.AccountType !== 'Bank' &&
                item.AccountType !== 'Other Income' &&
                item.AccountType !== 'Other Current Asset' &&
                item.AccountType !== 'Credit Card' &&
                item.AccountType !== 'Equity' &&
                item.AccountType !== 'Fixed Asset',
        );
        Object.entries(haulerChargeItems)
            .sort((a, b) => (a[1] > b[1] ? 1 : -1))
            .forEach((item) => {
                const found = currentPayableMapping.find((mapItem) => mapItem.internalName === item[0]);
                if (found) {
                    initialPayableMapping.push(found);
                } else {
                    initialPayableMapping.push({
                        id: '',
                        haulerID: '',
                        internalName: item[0],
                        externalName: accountsData[0].Id,
                        accountType: 'EXPENSE_ACCOUNT',
                        metadata: {
                            createdAt: '',
                            createdByUserID: '',
                            lastUpdatedAt: '',
                            lastUpdatedByUserID: '',
                        },
                    });
                }
            });
        const onlyBankAccounts = accountsData.filter((item) => item.AccountType === 'Bank');
        setQBBankAccounts(onlyBankAccounts);
        setQuickBooksSettings(settingsData);

        // const onlyCreditCardAccounts = accountsData.filter((item) => item.AccountType === 'Credit Card');
        // setQBCreditCardAccounts(onlyCreditCardAccounts);
        // setSelectedCreditCard(onlyCreditCardAccounts[0].Id);

        setQBItems(itemsData);
        setQBAccounts(onlyPayableAccounts);
        setReceivableMapping(initialReceivableMapping);
        setPayableMapping(initialPayableMapping);
    };

    const handleGetQuickBooksMapping = async (afterAuthAttempt?: boolean) => {
        setIsLoading(true);
        const mappingResponse = await client.quickBooks().getQuickBooksMapping();
        if (!mappingResponse || mappingResponse.status !== 200) {
            setShowQBConnectionNeeded(true);
            setIsLoading(false);
            if (afterAuthAttempt) {
                alert.getAlert({
                    message: 'An error occurred loading the mapping. If the error persists contact support.',
                    severity: 'error',
                });
            }
            return;
        }
        let itemsData = [];
        let accountsData = [];
        let settingsData: QuickbooksConnect.QuickbooksConnectSettings = {
            bankAccount: '',
            enableSync: true,
            haulerID: '',
            omitLineItems: ['Refund'],
            customLineItemNames: [],
            accountingIntegrationType: 'QUICKBOOKS_ONLINE',
            syncStatus: 'MANUAL',
            cutoffDate: '',
        };
        try {
            const itemsResponse = await client.quickBooks().quickBooksConnectItems();
            itemsData = itemsResponse?.data || [];
        } catch (error: any) {
            console.warn('quickBooksConnectItems error: ', error);
            if (error?.response?.data?.message === 'No Quickbooks OAuth token found') {
                setShowQBConnectionNeeded(true);
                setIsLoading(false);
                return;
            }
            alert.getAlert({
                message: 'An error occurred loading the QuickBooks items. If the error persists contact support.',
                severity: 'error',
            });
        }
        try {
            const accountsResponse = await client.quickBooks().quickBooksConnectAccounts();
            accountsData = accountsResponse?.data || [];
        } catch (error) {
            console.warn('quickBooksConnectAccounts error: ', error);
            alert.getAlert({
                message: 'An error occurred loading the QuickBooks accounts. If the error persists contact support.',
                severity: 'error',
            });
        }
        try {
            const settingsResponse = await client.quickBooks().getQuickBooksSettings();
            settingsData = settingsResponse?.data;
        } catch (error: any) {
            console.warn('settingsResponse error: ', error);
            if (error?.data?.responseCode !== 403) {
                alert.getAlert({
                    message: 'An error occurred loading the QuickBooks settings. If the error persists contact support.',
                    severity: 'error',
                });
            }
        }
        const { data } = mappingResponse;
        handleSetInitialMapping(data, itemsData, accountsData, settingsData);
        setIsLoading(false);
    };

    const handleAuthenticateQuickBooks = async () => {
        try {
            const baseURL = import.meta.env.DEV ? 'http://localhost:3000' : 'https://admin.sourgum.com/';
            const response = await client.quickBooks().authenticateQuickBooks();
            const qbURL = response?.data;
            const windowFeatures = 'resizable=no,scrollbars=no,status=no,location=no,toolbar=no,menubar=no,width=500,height=700';
            window.open(qbURL, 'popup', windowFeatures);
            window.addEventListener('message', (event) => {
                if (event.origin === baseURL && event.data === 'quickbooks-connection-successful') {
                    handleGetQuickBooksMapping(true);
                    setShowQBConnectionNeeded(false);
                }
            });
        } catch (error) {
            console.warn('authenticateQuickBooks error: ', error);
            alert.getAlert({
                message: 'An error occurred with the message: ' + JSON.stringify(error),
                severity: 'error',
            });
        }
    };

    useEffect(() => {
        handleGetQuickBooksMapping();
    }, []);

    const handleReceivableChange = (value: string, index: number) => {
        const newArray = [...receivableMapping];
        newArray[index] = { ...newArray[index], externalName: value, accountType: 'REVENUE_ACCOUNT' };
        setReceivableMapping(newArray);
    };

    const handlePayableChange = (value: string, index: number) => {
        const newArray = [...payableMapping];
        newArray[index] = { ...newArray[index], externalName: value, accountType: 'EXPENSE_ACCOUNT' };
        setPayableMapping(newArray);
    };

    const handleSave = async () => {
        setIsSubmitting(true);
        try {
            const cleanData = [...receivableMapping, ...payableMapping];

            const requests = cleanData.map(async (item) => {
                if (item.id) {
                    await client.quickBooks().deleteQuickBooksMapping(item.id);
                }

                return await client.quickBooks().createQuickBooksMapping({
                    internalName: item.internalName,
                    externalName: item.externalName,
                    accountType: item.accountType,
                });
            });
            try {
                await Promise.all(requests);
            } catch (error) {
                throw new Error('An error occurred saving the mapping');
            }
            try {
                await client.quickBooks().updateQuickBooksSettings({ ...quickBooksSettings, enableSync: true, syncStatus: 'MANUAL' });
            } catch (error) {
                throw new Error('An error occurred saving the settings');
            }
            setShowToast({
                message: 'Mapping and settings saved successfully',
                severity: 'success',
            });
        } catch (e) {
            setShowToast({
                message: 'An error occurred saving the mapping and settings',
                severity: 'error',
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    if (isLoading) return <Loading />;

    return (
        <div className="container mx-auto flex flex-col gap-10 px-3 pb-10 pt-7">
            <h1 className="text-xl font-semibold">Company Level Mapping to QuickBooks Chart of Accounts</h1>
            <div className="container mx-auto flex flex-1 flex-col items-center justify-center gap-10 px-3 pb-10 pt-7">
                <h1 className="text-2xl font-semibold">Connect to QuickBooks Online First</h1>
                <button className="btn-primary" type="button" onClick={() => handleAuthenticateQuickBooks()}>
                    Connect to QuickBooks Online
                </button>
            </div>
            <div className="rounded border border-solid border-[#D9DCE1] p-10">
                <h2 className="text-base font-semibold">
                    Cut Off Date<span className="text-red-500">*</span>
                </h2>
                <p>No invoices or payments will be synced to QuickBooks that were issued or paid before this date.</p>
                <hr className="my-5" />
                <div className="max-w-md">
                    <DatePicker
                        value={quickBooksSettings?.cutoffDate ? new Date(quickBooksSettings.cutoffDate) : ''}
                        onChange={(value) => setQuickBooksSettings({ ...quickBooksSettings, cutoffDate: value ? value.toISOString() : '' })}
                        label="Cut Off Date"
                    />
                </div>
            </div>
            <div className="rounded border border-solid border-[#D9DCE1] p-10">
                <h2 className="text-base font-semibold">
                    Bank account<span className="text-red-500">*</span>
                </h2>
                <p>Which bank account in QuickBooks Online do you use to move money in and out of from your operations?</p>
                <hr className="my-5" />
                <div className="max-w-md">
                    {/* TODO replace the selectedBank with a function that will save to DB on save */}
                    <Select
                        value={quickBooksSettings?.bankAccount}
                        onSelect={(value: string) => setQuickBooksSettings({ ...quickBooksSettings, bankAccount: value })}
                        label=""
                    >
                        {qbBankAccounts.map((item: any) => (
                            <SelectOption value={item.Id}>{item.Name}</SelectOption>
                        ))}
                    </Select>
                </div>
            </div>
            {/* <div className="p-10 rounded border border-solid border-[#D9DCE1]">
        <h2 className="text-base font-semibold ">
          Credit card<span className="text-red-500">*</span>
        </h2>
        <p>Which credit card account in QuickBooks Online do you use when you pay your vendors by credit card?</p>
        <hr className="my-5" />
        <div className="max-w-md">
          TODO replace the selectedCreditCard with a function that will save to DB on save
          <Select value={selectedCreditCard} onSelect={(value: string) => setSelectedCreditCard(value)} label="">
            {qbCreditCardAccounts.map((item: any) => (
              <SelectOption value={item.Id}>{item.Name}</SelectOption>
            ))}
          </Select>
        </div>
      </div> */}
            <div className="rounded border border-solid border-[#D9DCE1] p-10">
                <h2 className="text-base font-semibold">Receivables</h2>
                <table className="w-full table-fixed text-left">
                    <thead>
                        <tr className="text-sourgum-greyblue-600 text-sm">
                            <th className="px-0 py-5">Line Item</th>
                            <th className="px-0 py-5">QuickBooks Account</th>
                        </tr>
                    </thead>
                    <tbody>
                        {receivableMapping.map((item, index) => (
                            <tr key={'receivable' + item.internalName}>
                                <td className={cellStyle}>{item.internalName}</td>
                                <td className={cellStyle}>
                                    <Select
                                        value={item.externalName}
                                        onSelect={(value: string) => handleReceivableChange(value, index)}
                                        label=""
                                    >
                                        {qbItems.map((item: any) => (
                                            <SelectOption value={item.Id}>{item.Name}</SelectOption>
                                        ))}
                                    </Select>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="rounded border border-solid border-[#D9DCE1] p-10">
                <h2 className="text-base font-semibold">Payables</h2>
                <table className="w-full table-fixed text-left">
                    <thead>
                        <tr className="text-sourgum-greyblue-600 text-sm">
                            <th className="px-0 py-5">Line Item</th>
                            <th className="px-0 py-5">QuickBooks Account</th>
                        </tr>
                    </thead>
                    <tbody>
                        {payableMapping.map((item, index) => (
                            <tr key={'payable' + item.internalName}>
                                <td className={cellStyle}>{haulerChargeItems[item.internalName]}</td>
                                <td className={cellStyle}>
                                    <Select
                                        label=""
                                        onSelect={(value: string) => handlePayableChange(value, index)}
                                        value={item.externalName}
                                    >
                                        {qbAccounts.map((item: any) => (
                                            <SelectOption value={item.Id} label={item.Name}>
                                                <span className={`${item.SubAccount ? 'flex pl-5' : ''}`}>
                                                    {item.SubAccount && <div className="absolute left-5 top-0 h-10 w-1 bg-slate-100" />}
                                                    {item.Name}
                                                </span>
                                            </SelectOption>
                                        ))}
                                    </Select>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="flex justify-end">
                <button className="btn-dark-grey-outlined mr-5" type="button" disabled={isSubmitting}>
                    Cancel
                </button>
                <button className="btn-primary" type="submit" disabled={isSubmitting} onClick={() => handleSave()}>
                    Save Changes
                    {isSubmitting && <SourLoading className="text-sourgum-greyblue-900" size="h-4 w-4 ml-2" />}
                </button>
            </div>
        </div>
    );
};

export default QuickBooksMapping;
